<template>
  <tr :class="{ error: !isActive }">
    <td>
      <div v-if="!isAdmin" >
        <v-icon v-if="isActive">mdi-check</v-icon>
        <v-icon v-if="!isActive">mdi-close</v-icon>
      </div>
      <v-checkbox
        v-if="isAdmin"
        v-model="activeState"
        @click="setIsActive(!isActive)"
      />
    </td>
    <td class="address">{{ address }}</td>
    <td>{{ balance | balanceFilter }}</td>
    <td>
      <router-link
        class="d-flex align-center"
        :to="{ name: 'Address', params: { id }, }"
        v-slot="{ navigate }"
      >
        <v-btn
          v-if="isActive"
          @click="navigate"
          fab
          small
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn
          v-if="!isActive"
          @click="navigate"
          fab
          small
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </router-link>
    </td>
  </tr>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AddressListItem',
  data: () => ({
    activeState: null,
  }),
  computed: {
    ...mapGetters([ 'isAdmin' ]),
  },
  props: {
    id: Number,
    address: String,
    balance: Number,
    isActive: Boolean,
  },
  watch: {
    activeState( val, oldVal ) {
      if ( oldVal === null ) return
      this.setIsActive( val )
    },
  },
  created() {
    this.activeState = this.isActive
  },
  filters: {
    balanceFilter: v => (Math.round( v*100000000 )/100000000).toLocaleString(),
  },
  methods: {
    ...mapActions([
      'updateAddress',
      'resetNotification',
      'showNotification',
      'showError',
    ]),
    async setIsActive( isActive ) {
      this.resetNotification()
      const { error } = await this.updateAddress({
        addressId: this.id,
        addressData: { isActive },
      })

      if ( error ) {
        this.activeState = this.isActive
        return this.showError( error )
      }
      this.showNotification( 'Address Updated' )
    },
  },
}
</script>

<style scoped>
.address {
  max-width: 10rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (min-width: 600px) {
  .address {
    max-width: 20rem;
  }
}

@media (min-width: 960px) {
  .address {
    max-width: 26rem;
  }
}
</style>
