<template>
  <v-app class=".app">
    <v-app-bar
      app
      class="primary"
      dark
    >
      <div></div>
      <v-app-bar-nav-icon class="logo-container">
        <router-link
          :to="{ name: 'Home' }"
          class="d-flex align-center"
        >
          <v-img
            alt="Blockchain Ventures Logo"
            class="shrink logo"
            contain
            src="@/assets/logo.png"
            transition="scale-transition"
            width="40"
          />
        </router-link>
      </v-app-bar-nav-icon>

      <v-toolbar-title
        min-width="100"
        class="d-none d-md-block"
      >Blockchain Ventures Association</v-toolbar-title>

      <v-spacer></v-spacer>

      <NavLink class="d-none d-md-block" route="Home" icon="home">Home</NavLink>
      <NavLink
        v-if="isLoggedIn"
        route="Proposals"
        icon="vote"
      >Proposals</NavLink>
      <NavLink
        v-if="isLoggedIn"
        route="Profile"
        icon="account"
      >Profile</NavLink>
      <NavLink
        v-if="isAdmin"
        route="Accounts"
        icon="wrench"
      >Admin</NavLink>
      <v-btn
        v-if="isLoggedIn"
        class="nav-link mx-2"
        @click="logout"
      >
        <v-icon>mdi-logout</v-icon>
        <span class="mr-2 d-none d-sm-block">Logout</span>
      </v-btn>
      <NavLink
        v-if="!isLoggedIn"
        route="Login"
        icon="login"
      >Login</NavLink>
    </v-app-bar>

    <v-main class="px-6 mt-5">
      <transition name="navigate" mode="out-in">
        <router-view elevate='3' :key="$route.fullPath" />
      </transition>
    </v-main>

    <v-footer
      class="footer d-flex justify-center"
      padless
    >
      <v-btn
        color="white"
        text
        href="https://ass-docs.s3-us-west-2.amazonaws.com/privacy-policy.pdf"
        target="_blank"
      >Privacy Policy</v-btn>
    </v-footer>

    <v-snackbar v-model="showError" color="error">{{ errorNotification }}</v-snackbar>
    <v-snackbar v-model="showNotification">{{ notification }}</v-snackbar>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NavLink from '@/components/NavLink'

export default {
  name: 'App',
  data: () => ({
    showError: false,
    showNotification: false,
  }),
  watch: {
    errorNotification() {
      this.showError = !!this.errorNotification
    },
    notification() {
      this.showNotification = !!this.notification
    },
  },
  async created() {
    await this.login({ token: this.token })
  },
  computed: {
    ...mapGetters([
      'isAdmin',
      'isLoggedIn',
      'notification',
      'errorNotification',
      'token'
    ]),
  },
  methods: {
    ...mapActions([ 'logout', 'login' ]),
  },
  components: { NavLink },
};
</script>

<style>
  @font-face {
    font-family: 'Lato';
    src: local('Lato'),
     url(./assets/fonts/Lato-Regular.ttf) format('truetype');
  }

  * {
    font-family: Lato !important
  }
</style>

<style scoped>
  main, footer {
    background: url(./assets/colorized.png);
  }

  .logo-container {
    background: white;
    border-radius: 99999999px;
    padding: 5px;
    margin-right: 10px 
  }

  @keyframes coming {
    from {
      transform: translateX(-50px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes going {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(50px);
      opacity: 0;
    }
  }

  .navigate-enter-active {
    animation: coming 0.3s;
    opacity: 0;
  }

  .navigate-leave-active {
    animation: going 0.3s;
  }

  .footer {
    opacity: 0.95;
  }
</style>
