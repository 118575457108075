<template>
  <div class="acceptance">
    <div class="ma-2 d-none d-sm-flex d-md-none">
      <v-progress-circular
        v-if="accepted + rejected === 0"
        size="100"
        width="5"
        :value="acceptedToRejectedPercent"
      >{{ acceptedToRejectedRatio | percentFilter }}</v-progress-circular>
      <v-progress-circular
        class="active"
        v-if="accepted + rejected > 0"
        size="100"
        width="5"
        color="primary"
        :value="acceptedToRejectedPercent"
      >{{ acceptedToRejectedRatio | percentFilter }}</v-progress-circular>
    </div>
    
    <div class="ma-2 d-none d-md-flex flex-column align-center">
      <v-progress-circular
        v-if="accepted + rejected === 0"
        size="150"
        width="15"
        :value="acceptedToRejectedPercent"
      >{{ acceptedToRejectedRatio | percentFilter }}</v-progress-circular>
      <v-progress-circular
        v-if="accepted + rejected > 0"
        class="active"
        size="150"
        width="15"
        color="primary"
        :value="acceptedToRejectedPercent"
      >{{ acceptedToRejectedRatio | percentFilter }}</v-progress-circular>
      <div class="my-2 text-body-2">{{ abstainedPercent | percentFilter }} abstaining</div>
    </div>
  </div>
</template>

<script>
import { percentFilter } from '@/filters'

export default {
  name: 'Acceptance',
  props: {
    accepted: Number,
    rejected: Number,
    abstained: Number,
  },
  computed: {
    acceptedPercent() { return this.accepted/100 },
    rejectedPercent() { return this.rejected/100 },
    abstainedPercent() { return this.abstained/100 },
    acceptedToRejectedPercent() { return this.acceptedToRejectedRatio*100 },
    acceptedToRejectedRatio() {
      const totalVotes = this.accepted + this.rejected
      if ( totalVotes === 0 ) return 0
      return (this.accepted / totalVotes)
    },
  },
  filters: {
    percentFilter,
  },
}
</script>

<style>
/* Somehow, we can't use scoped styles  to apply these svg styles. I don't know why. */
.acceptance .active .v-progress-circular__underlay {
  stroke: rgba(223, 117, 116, 1);
}
</style>
