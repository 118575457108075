<template>
  <div class="proposal-create mx-auto">
    <v-card>
      <v-card-title>Create Proposal</v-card-title>
      <v-container>
        <v-form v-model="valid" class="mx-auto form d-flex flex-column">
          <v-text-field
            v-model="name"
            placeholder="Name"
            :rules="nameRules"
            required
            ref="autofocus"
          >
          </v-text-field>
          <v-textarea
            v-model="description"
            placeholder="Description"
            required
            counter
          ></v-textarea>

          <v-card-text>Voting Opens At</v-card-text>
          <div class="d-flex justify-center my-2">
            <v-date-picker
              elevation="3"
              class="ma-1"
              v-model="votingOpensAtDate"
              :rules="votingOpensAtDateRules"
              required
            ></v-date-picker>
            <v-time-picker
              elevation="3"
              class="ma-1"
              v-model="votingOpensAtTime"
              :rules="votingOpensAtTimeRules"
              required
            ></v-time-picker>
          </div>

          <v-divider class="ma-1" />
          <v-card-text>Voting Closes At</v-card-text>
          <div class="d-flex justify-center my-2">
            <v-date-picker
              elevation="3"
              class="ma-1"
              v-model="votingClosesAtDate"
              :rules="votingClosesAtDateRules"
              required
            ></v-date-picker>
            <v-time-picker
              elevation="3"
              class="ma-1"
              v-model="votingClosesAtTime"
              :rules="votingClosesAtTimeRules"
              required
            ></v-time-picker>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="submit" color="primary">
              <v-icon>mdi-plus</v-icon>
              <span class='mr-2'>Create</span>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
    <div class="mx-auto my-3">
      <ProposalSummary
        :name="name"
        :description="description"
        :votingOpensAt="votingOpensAt"
        :votingClosesAt="votingClosesAt"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import ProposalSummary from '@/components/ProposalSummary'
import config from '@/config'

export default {
  name: 'ProposalCreate',
  data: () => {
    const now = Date.now()

    const votingOpensAt = new Date(
      now + config.proposals.creation.votingOpensAtDelay
    )
    const votingOpensAtDate = moment(votingOpensAt).format('YYYY-MM-DD') // extract date
    const votingOpensAtTime = moment(votingOpensAt).format('HH:mm:ss') // extract time

    const votingClosesAt = new Date(
      votingOpensAt.getTime() + config.proposals.creation.votingClosesAtBuffer
    )
    const votingClosesAtDate = moment(votingClosesAt).format('YYYY-MM-DD') // extract date
    const votingClosesAtTime = moment(votingClosesAt).format('HH:mm:ss') // extract time

    return {
      name: null,
      nameRules: [ v => !!v || 'Please specify a name.' ],
      description: null,
      descriptionRules: [ v => !!v || 'Please specify a description.' ],

      votingOpensAt,
      votingOpensAtDate,
      votingOpensAtDateRules: [ v => !!v || 'Please specify a voting open date.' ],
      votingOpensAtTime,
      votingOpensAtTimeRules: [ v => !!v || 'Please specify a voting open time.' ],

      votingClosesAt,
      votingClosesAtDate,
      votingClosesAtDateRules: [ v => !!v || 'Please specify a voting close date.' ],
      votingClosesAtTime,
      votingClosesAtTimeRules: [ v => !!v || 'Please specify a voting close time.' ],

      valid: false,
      response: {},
      showFormError: false,
      formError: null,
    }
  },
  mounted() {
    this.$refs.autofocus.focus()
  },
  watch: {
    votingOpensAtTime() {
      this.votingOpensAt = new Date( `${this.votingOpensAtDate} ${this.votingOpensAtTime}`)
    },
    votingOpensAtDate() {
      this.votingOpensAt = new Date( `${this.votingOpensAtDate} ${this.votingOpensAtTime}`)
    },
    votingClosesAtTime() {
      this.votingClosesAt = new Date( `${this.votingClosesAtDate} ${this.votingClosesAtTime}`)
    },
    votingClosesAtDate() {
      this.votingClosesAt = new Date( `${this.votingClosesAtDate} ${this.votingClosesAtTime}`)
    },
  },
  methods: {
    ...mapActions([
      'createProposal',
      'showNotification',
      'showError',
      'resetNotification'
    ]),

    async submit( event ) {
      event.preventDefault()

      this.resetNotification()
      if ( !this.valid ) {
        return this.showError( 'Please correct errors before submitting.' )
      }

      const { error, proposal } = await this.createProposal({
        name: this.name,
        description: this.description,
        votingOpensAt: this.votingOpensAt,
        votingClosesAt: this.votingClosesAt,
      })

      if ( error ) {
        return this.showError( error )
      }

      this.showNotification( 'Proposal Created' )
      this.$router.push({
        name: 'Proposal',
        params: { id: proposal.id },
      })
    }
  },
  components: {
    ProposalSummary,
  },
}
</script>

<style scoped>
  .proposal-create {
    max-width: 100rem;
  }

  .form {
    max-width: 40rem;
  }
</style>

