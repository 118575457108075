export default {
  //baseUrl: `${process.env.VUE_APP_API_HOST}:${process.env.VUE_APP_API_PORT}`,
  baseUrl: `https://api.blockchain.ventures`,
  token: {
    refreshBuffer: 30*1000,
  },
  proposals: {
    creation: {
      votingOpensAtDelay: 24*60*60*1000,
      votingClosesAtBuffer: 24*60*60*1000,
    },
  },
}
