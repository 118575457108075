<template>
  <v-list>
    <div v-for='(account, index) in accounts' :key='account.id'>
      <AccountListItem v-bind='account' />
      <v-divider v-if='index < accounts.length - 1'></v-divider>
    </div>
  </v-list>
</template>

<script>
import AccountListItem from '@/components/AccountListItem.vue'

export default {
  name: 'AccountList',
  components: {
    AccountListItem,
  },
  props: {
    accounts: Array,
  },
}
</script>

<style scoped>
</style>
