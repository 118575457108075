<template>
  <div class="proposal mx-auto">
    <Loading v-if="loading" type="card-avatar" />
    <div v-if="!loading" class="d-flex flex-column flex-md-row align-center">
      <PercentProgress
        v-if="isLoggedIn"
        :value='voteWeight'
        class="ma-3 order-2 order-md-0"
      >Your Vote Weight</PercentProgress>
      <div class="proposal-info d-flex flex-column">
        <ProposalSummary class="ma-3" v-bind="proposal" />
        <v-card class="flex ma-3">
          <div class="d-none d-md-flex flex-column">
            <ProgressBar
              name="Accepted"
              :value="acceptedPercent"
              color="primary lighten-2"
              :height="50"
            />
            <ProgressBar
              name="Rejected"
              :value="rejectedPercent"
              color="red lighten-2"
              :height="50"
            />
            <ProgressBar
              name="Abstained"
              :value="abstainedPercent"
              color="grey lighten-1"
              :height="50"
            />
          </div>

          <!--
            NOTE: Duplicate of above
          -->
          <div class="d-flex d-md-none flex-column">
            <ProgressBar
              name="Accepted"
              :value="acceptedPercent"
              color="primary lighten-2"
              :height="20"
            />
            <ProgressBar
              name="Rejected"
              :value="rejectedPercent"
              color="red lighten-2"
              :height="20"
            />
            <ProgressBar
              name="Abstained"
              :value="abstainedPercent"
              color="grey lighten-1"
              :height="20"
            />
          </div>

          <v-card-actions
            v-if="active && isLoggedIn"
            class="d-flex justify-space-between"
          >
            <v-btn
              color="error"
              :disabled="yourVote === 'REJECT'"
              @click="submitVote('reject')"
              @mouseenter="votePreview('rejected')"
              @mouseleave="resetVotePreview('rejected')"
              x-large
            >
              <v-icon class="d-none d-sm-inline">mdi-thumb-down</v-icon>
              <span>Reject</span>
            </v-btn>
            <v-btn
              @click="submitVote('abstain')"
              :disabled="yourVote === 'ABSTAIN'"
              @mouseenter="votePreview('abstained')"
              @mouseleave="resetVotePreview('abstained')"
              x-large
            >
              <v-icon class="d-none d-sm-inline">mdi-minus</v-icon>
              <span>Abstain</span>
            </v-btn>
            <v-btn
              color="primary"
              :disabled="yourVote === 'ACCEPT'"
              @click="submitVote('accept')"
              @mouseenter="votePreview('accepted')"
              @mouseleave="resetVotePreview('accepted')"
              x-large
            >
              <v-icon class="d-none d-sm-inline">mdi-thumb-up</v-icon>
              <span>Accept</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import PercentProgress from '@/components/PercentProgress'
import ProgressBar from '@/components/ProgressBar'
import ProposalSummary from '@/components/ProposalSummary'
import _ from 'lodash'

export default {
  name: 'ProposalView',
  watch: {
    proposal: function( proposal ) {
      this.loading = !proposal
      this.submitting = false

      if ( proposal ) {
        this.name = proposal.name
        this.description = proposal.description
        this.votingOpensAt = proposal.votingOpensAt
        this.votingClosesAt = proposal.votingClosesAt
        this.yourVote = proposal.yourVote
        this.totalVotePortion = proposal.totalVotePortion

        this.accepted = proposal.accepted
        this.rejected = proposal.rejected
        this.abstained = proposal.abstained
      }
    },
    isLoggedIn( isLoggedIn ) {
      if ( isLoggedIn ) {
        this.refreshVoteWeight()
      }
    },
  },
  computed: {
    ...mapGetters([
      'proposal',
      'voteWeight',
      'isLoggedIn'
    ]),
    state() {
      const now = new Date
      if ( now < this.votingOpensAt ) return 'Pending'
      if ( now < this.votingClosesAt ) return 'Active'
      return 'Closed'
    },
    pending() {
      if ( new Date() >= this.votingOpensAt ) return false
      return true
    },
    active() {
      if ( new Date() < this.votingOpensAt ) return false
      if ( new Date() >= this.votingClosesAt ) return false
      return true
    },
    completed() {
      if ( new Date() < this.votingClosesAt ) return false
      return true
    },

    acceptedPercent() { return Math.round(this.accepted * 100) },
    rejectedPercent() { return Math.round(this.rejected * 100) },
    abstainedPercent() { return Math.round(this.abstained * 100) },

    relativeVotePortion() {
      // avoid divide by zero situation
      if ( this.voteWeight === 0 ) return 0
      if ( this.totalVotePortion === 0 ) return 1

      // compute new total weight depending on if your vote is already factored in
      const newTotalWeight = this.yourVote
        ? this.totalVotePortion
        : (this.voteWeight + this.totalVotePortion)
      return this.voteWeight / newTotalWeight
    },

    yourVoteTotalLabel() {
      return this.yourVote ? {
        ACCEPT: 'accepted',
        REJECT: 'rejected',
        ABSTAIN: 'abstained',
      }[ this.yourVote ] : null
    },

    yourVoteLabel() {
      return this.yourVote ? {
        ACCEPT: 'accepted',
        REJECT: 'rejected',
        ABSTAIN: 'abstained',
      }[ this.yourVote ] : null
    },
  },
  async created() {
    this.fetchProposal( this.id )
    if ( this.isLoggedIn ) {
      await this.refreshVoteWeight()
    }
  },
  props: {
    id: Number,
  },
  methods: {
    ...mapActions([
      'fetchProposal',
      'refreshVoteWeight',
      'accept',
      'reject',
      'abstain',
      'showNotification',
      'showError',
      'resetNotification'
    ]),

    async submitVote( type ) {
      this.submitting = true

      this.resetNotification()
      const data = await this[type]( this.id )

      if ( data.error ) {
        this.submitting = false
        return this.showError( data.error )
      }

      this.showNotification( 'Vote submitted' )
    },

    votePreview( type ) {
      if ( this.yourVote ) {
        this[ this.yourVoteLabel ] -= this.relativeVotePortion
      } else {
        if ( type !== 'accepted' ) this.accepted -= this.relativeVotePortion
        if ( type !== 'rejected' ) this.rejected -= this.relativeVotePortion
        if ( type !== 'abstained' ) this.abstained -= this.relativeVotePortion
      }

      this[ type ] += this.relativeVotePortion

      this.accepted = _.clamp( this.accepted, 0, 1 )
      this.rejected = _.clamp( this.rejected, 0, 1 )
      this.abstained = _.clamp( this.abstained, 0, 1 )
    },

    resetVotePreview() {
      this.accepted = this.proposal.accepted
      this.rejected = this.proposal.rejected
      this.abstained = this.proposal.abstained
    },

  },
  components: {
    Loading,
    PercentProgress,
    ProgressBar,
    ProposalSummary,
  },
  data: () => ({
    error: null,
    loading: true,
    submitting: false,
    response: {},

    totalVotePortion: 0,

    name: null,
    description: null,
    accepted: null,
    rejected: null,
    abstained: null,

    originalAccepted: null,
    originalRejected: null,
    originalAbstained: null,
  }),
}
</script>

<style scoped>
  .proposal {
    max-width: 100rem;
  }
  .proposal-info {
    flex: 1 1 100%
  }
  .description {
    border-left: 2px solid grey;
  }
</style>

