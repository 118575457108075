<template>
  <div class="login mx-auto">
    <v-card>
      <v-card-title>Login</v-card-title>
      <v-form v-model="valid" class="form mx-auto">
        <v-container>
          <v-text-field
            v-model="name"
            placeholder="Name"
            required
            @keypress.enter="submit"
            :rules="nameRules"
            ref="autofocus"
          >
          </v-text-field>
          <v-text-field
            v-model="password"
            placeholder="Password"
            type="password"
            required
            @keypress.enter="submit"
            :rules="passwordRules"
          >
          </v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="submit" color="primary" x-large>
              <v-icon>mdi-login</v-icon>
              <span class='mr-2'>Log In</span>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LoginView',
  data: () => ({
    name: null,
    nameRules: [ v => !!v || 'Please enter a name.' ],
    password: null,
    passwordRules: [ v => !!v || 'Please enter a password.' ],
    valid: false,
  }),
  mounted() {
    this.$refs.autofocus.focus()
  },
  methods: {
    ...mapActions([
      'login',
      'showNotification',
      'showError',
      'resetNotification'
    ]),

    async submit( event ) {
      event.preventDefault()
      this.resetNotification()
      if ( !this.valid ) {
        return this.showError( 'Please correct errors before submitting.' )
      }

      this.response = null
      const { error } = await this.login({
        name: this.name,
        password: this.password,
      })

      if ( error ) {
        return this.showError( error )
      }

      this.showNotification( 'Logged In' )
      this.$router.push({ name: 'Home' })
    },
  },
}
</script>

<style scoped>
  .login {
    max-width: 100rem;
  }

  .form {
    max-width: 40rem;
  }

  * {
    font-size: 1.25rem;
  }
</style>
