<template>
  <div class="address d-flex flex-column align-center mx-auto">
    <v-card class="ma-2 pa-2">
      <v-card-title>Address</v-card-title>
      <v-card-text class="text-caption text-sm-subtitle-1 text-md-h5 text-lg-h4 text-center">
        <span>{{ address.address }}</span>
      </v-card-text>
      <v-container
        v-if="address.isActive"
        class="d-flex justify-center align-center"
      >
        <v-icon
          color="green"
          x-large
        >mdi-check</v-icon>
        <div class="text-h3 text-md-h2">Verified</div>
      </v-container>
      <v-container
        v-if="!address.isActive"
        class="d-flex justify-center align-center"
      >
        <v-icon
          color="red"
          x-large
          v-if="!address.isActive"
        >mdi-close</v-icon>
        <div class="text-h4 text-md-h2">Unverified</div>
      </v-container>
      <v-container
        v-if="address.isActive"
        class="d-flex justify-center align-center"
      >
      <div class="title">Balance: {{ address.balance }}</div>
      </v-container>
    </v-card>

    <v-card class="sign ma-2 pa-4">
      <v-form
        v-model="valid"
        v-if="!address.isActive"
        class="form mx-auto"
      >
        <v-card-text>
          <div class="text-subtitle-1">Sign the following message to prove you own this address:</div>
          <div class="message text-center text-caption text-sm-subtitle-2 text-md-h5 pa-3">{{ signature.message }}</div>
        </v-card-text>
        <v-text-field
          v-model="newSignature"
          placeholder="Signature"
          @keypress.enter="submit"
          :rules="newSignatureRules"
          ref="autofocus"
          required
        />
        <v-card-actions>
          <v-spacer />
          <v-btn @click="submit" color="primary">
            <v-icon>mdi-pencil</v-icon>
            <span class='mr-2'>Verify</span>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AddressView',
  data: () => ({
    newSignature: null,
    newSignatureRules: [ v => !!v || 'Please enter a signature.' ],
    valid: false,
  }),
  computed: {
    ...mapGetters([ 'address', 'signature' ]),
  },
  watch: {
    address() {
      if ( !this.address.isActive )
        this.$refs.autofocus?.focus()
    },
  },
  methods: {
    ...mapActions([
      'fetchAddress',
      'fetchSignature',
      'resetNotification',
      'showError',
      'showNotification',
      'signSignature',
    ]),
    async submit( event ) {
      event.preventDefault()

      this.resetNotification()
      if ( !this.valid ) {
        return this.showError( 'Please correct errors before submitting.' )
      }

      this.response = null
      const { error } = await this.signSignature({
        signatureId: this.signature.id,
        signature: this.newSignature,
      })

      if ( error ) {
        return this.showError( error )
      }

      this.showNotification( 'Address Verified' )
      await this.fetchAddress( this.id )
    },
  },
  async created() {
    const { error } = await this.fetchAddress( this.id )
    if ( error ) {
      return this.$router.push({ name: 'Login' })
    }
    await this.fetchSignature( this.id )
  },
  props: {
    id: Number,
  },
}
</script>

<style scoped>
  .address {
    max-width: 100rem
  }
  .form,
  .sign {
    max-width: 100rem;
  }

  .message {
    overflow-wrap: anywhere;
  }
</style>
