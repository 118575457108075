<template>
  <div>
    <Loading v-if="loading" />
    <div
      v-if="!loading"
      class="account d-flex flex-column flex-md-row mx-auto align-center justify-center"
    >
      <PercentProgress
        :value="voteWeight"
        class="ma-2"
      >{{ voteWeightTitle }}</PercentProgress>

      <div class="profile-data d-flex flex-column">
        <Profile
          v-bind="account"
          class="ma-2"
        />
        <AddressList
          :addresses="addresses"
          :accountId="account.id"
          class="ma-2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AddressList from '@/components/AddressList'
import PercentProgress from '@/components/PercentProgress'
import Profile from '@/components/Profile'
import Loading from '@/components/Loading'

export default {
  name: 'AccountView',
  watch: {
    account( account ) {
      if ( account ) {
        this.accountId = account.id
        this.name = account.name
        this.email = account.email
        this.addresses = account.addresses
        this.votes = account.votes
      }
    },
    async id( newId ) {
      const { error } = await this.fetchAccount( newId )
      if ( error ) return this.$router.push({ name: 'Login' })
    },
  },
  computed: {
    ...mapGetters([
      'account',
      'isLoggedIn',
      'refreshVoteWeight',
      'voteWeight',
    ]),
    voteWeightTitle() {
      if ( this.id ) return 'Vote Weight'
      return 'Your Vote Weight'
    },
  },
  async mounted() {
    const { error } = await this.fetchAccount( this.id )
    if ( error ) return this.$router.push({ name: 'Login' })
    this.loading = false
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  methods: {
    ...mapActions([
      'fetchAccount',
    ]),

  },
  data: () => ({
    loading: true,
    valid: false,

    accountId: null,
    addresses: [],
    email: null,
    name: null,
    votes: 0,
  }),
  components: {
    AddressList,
    PercentProgress,
    Profile,
    Loading,
  },
}
</script>

<style scoped>
  .profile-data {
    flex: 1 1 100%;
    width: 100%;
  }
  .account {
    max-width: 100rem;
  }
</style>

