<template>
  <v-list-item :class="{ error: !isActive }">
    <v-list-item-content>
      <v-list-item-title>{{ name }}</v-list-item-title>
      <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <router-link :to="{ name: 'Account', params: { id } }" v-slot="{ navigate }">
        <v-btn
          fab
          color="primary"
          @click="navigate"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </router-link>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AccountListItem',
  props: {
    id: Number,
    name: String,
    email: String,
    isActive: Boolean,
  },
  methods: {
    ...mapActions([ 'vote' ]),
  },
}
</script>

<style scoped>
</style>
