export default {

  state: {
    notification: null,
    errorNotification: null,
  },

  getters: {
    notification: (state) => state.notification,
    errorNotification: (state) => state.errorNotification,
  },

  mutations: {
    setNotification( state, notification ) {
      state.notification = notification
    },
    setErrorNotification( state, errorNotification ) {
      state.errorNotification = errorNotification
    },
  },

  actions: {
    showNotification( {commit}, notification ) {
      commit( 'setNotification', null )
      commit( 'setErrorNotification', null )
      commit( 'setNotification', notification )
    },
    showError( {commit}, errorNotification ) {
      commit( 'setNotification', null )
      commit( 'setErrorNotification', null )
      commit( 'setErrorNotification', errorNotification )
    },
    resetNotification( {commit} ) {
      commit( 'setNotification', null )
      commit( 'setErrorNotification', null )
    },
  },
}

