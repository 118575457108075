<template>
  <div class="change-password mx-auto">
    <v-card>
      <v-card-title>Change Password</v-card-title>
      <v-form class="form mx-auto" v-model="valid">
        <v-container>
          <v-text-field
            v-model="password"
            placeholder="Password"
            required
            type="password"
            :rules="passwordRules"
            @keypress.enter="submit"
            ref="autofocus"
          >
          </v-text-field>
          <v-text-field
            v-model="confirmPassword"
            placeholder="Confirm Password"
            required
            type="password"
            @keypress.enter="submit"
            :rules="confirmPasswordRules"
          >
          </v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="submit" color="primary">
              <v-icon>mdi-content-save</v-icon>
              <span class='mr-2'>Save</span>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'PasswordEditView',
  data: () => ({
    password: null,
    passwordRules: [ v => !!v || 'Password must not be blank' ],
    confirmPassword: null,
    response: {},
    valid: false,
  }),
  mounted() {
    this.$refs.autofocus.focus()
  },
  computed: {
    confirmPasswordRules() {
      return [
        v => v === this.password || 'Passwords must match.',
      ]
    },
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  methods: {
    ...mapActions([
      'setPassword',
      'showNotification',
      'showError',
      'resetNotification',
    ]),
    async submit( event ) {
      event.preventDefault()

      this.resetNotification()
      if ( !this.valid ) {
        return this.showError( 'Please correct errors before submitting.' )
      }

      const { error } = await this.setPassword({
        password: this.password,
        accountId: this.id,
      })

      if ( error ) {
        return this.showError( error )
      }

      this.showNotification( 'Password Changed' )
      this.$router.back()
    },
  },
}
</script>

<style scoped>
  .change-password {
    max-width: 100rem;
  }

  .form {
    max-width: 40rem;
  }
</style>
