import axios from 'axios'
import config from '../config/index.js'

export default {
  state: {
    numSeats: 0,
  },

  getters: {
    numSeats: (state) => state.numSeats,
  },

  mutations: {
    setNumSeats( state, numSeats ) {
      state.numSeats = numSeats
    }
  },

  actions: {
    async fetchNumSeats( {commit} ) {
      const { data } = await axios.get( `${config.baseUrl}/numSeats` )
      if ( data.error ) {
        console.error( data.error )
        return data
      }
      commit( 'setNumSeats', data.numSeats )
    },

  },
}

