import axios from 'axios'
import config from '../config/index.js'

axios.defaults.headers.common.Authorization = localStorage.getItem('token')

export default {
  state: {
    refreshTimer: null,
    token: localStorage.getItem( 'token' ),
    tokenLifetime: localStorage.getItem( 'tokenLifetime' ),
    tokenCreatedAt: localStorage.getItem( 'tokenCreatedAt' ),
    isAdmin: false,
    isLoggedIn: false,
  },

  getters: {
    token: (state) => state.token,
    isAdmin: (state) => state.isAdmin,
    isLoggedIn: (state) => state.isLoggedIn,
  },

  mutations: {
    login( state, { token, tokenLifetime, isAdmin, name } ) {
      state.token = token
      state.tokenLifetime = tokenLifetime
      state.tokenCreatedAt = Date.now()
      state.isAdmin = isAdmin
      state.isLoggedIn = true
      state.yourName = name

      localStorage.setItem( 'token', state.token )
      localStorage.setItem( 'tokenLifetime', state.tokenLifetime )
      localStorage.setItem( 'tokenCreatedAt', state.tokenCreatedAt )
      axios.defaults.headers.common.Authorization = state.token
    },

    logout( state ) {
      state.token = null
      state.tokenLifetime = null
      state.tokenCreatedAt = null
      state.isAdmin = false
      state.isLoggedIn = false

      localStorage.removeItem( 'token' )
      localStorage.removeItem( 'tokenLifetime' )
      localStorage.removeItem( 'tokenCreatedAt' )
      axios.defaults.headers.common.Authorization = state.token
    },

    setPassword() {
    },

    setToken( state, { token, tokenLifetime }) {
      state.token = token
      state.tokenLifetime = tokenLifetime
      state.tokenCreatedAt = Date.now()
      state.isLoggedIn = true
      axios.defaults.headers.common.Authorization = state.token

      localStorage.setItem( 'token', state.token )
      localStorage.setItem( 'tokenLifetime', state.tokenLifetime )
      localStorage.setItem( 'tokenCreatedAt', state.tokenCreatedAt )
    },
  },

  actions: {
    async login( {commit, dispatch}, credentials = {} ) {

      const { data: loginResponse } = await axios.post( `${config.baseUrl}/login`, credentials )
      if ( loginResponse.error ) {
        return loginResponse
      }

      const { token: newToken, tokenLifetime, isAdmin, name } = loginResponse

      commit( 'login', {
        token: newToken,
        tokenLifetime,
        isAdmin,
        name,
      })

      setTimeout(
        () => dispatch( 'refreshToken' ),
        tokenLifetime*1000 - config.token.refreshBuffer
      )

      return loginResponse
    },

    async logout({ commit, state }) {
      await axios.post( `${config.baseUrl}/logout`, {
        token: state.token,
      })
      commit( 'logout' )
    },

    async setPassword({ commit }, { password, accountId }) {
      const { data: passwordResponse } = await axios.put( `${config.baseUrl}/account/password`, {
        password,
        accountId,
      })
      commit( 'setPassword' )
      return passwordResponse
    },

    async refreshToken({ commit, dispatch }) {
      const { data: tokenResponse } = await axios.post( `${config.baseUrl}/token/refresh` )

      if ( tokenResponse.error ) {
        return tokenResponse
      }

      const { token, tokenLifetime } = tokenResponse 

      commit( 'setToken', {
        token,
        tokenLifetime,
      })

      setTimeout(
        () => dispatch( 'refreshToken' ),
        tokenLifetime*1000 - config.token.refreshBuffer
      )

      return tokenResponse
    }
  },
}
