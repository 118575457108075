<template>
  <div class="accounts mx-auto">
    <Loading v-if="loading" />
    <div v-if="!loading">
      <AccountList :accounts='accounts' />
      <router-link :to="{ name: 'AccountCreate' }" v-slot="{ navigate }">
        <v-fab-transition>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                fab
                fixed
                large
                bottom
                right
                @click="navigate"
                v-on="on"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Create New User</span>
          </v-tooltip>
        </v-fab-transition>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AccountList from '@/components/AccountList'
import Loading from '@/components/Loading'

export default {
  name: 'AccountsView',
  components: {
    AccountList,
    Loading,
  },
  computed: {
    ...mapGetters([ 'accounts' ])
  },
  watch: {
    accounts: function( accounts ) {
      this.loading = !accounts
    },
  },
  created() {
    this.fetchAccounts()
  },
  methods: {
    ...mapActions([ 'fetchAccounts' ]),
  },
  data: () => ({
    error: null,
    loading: true,
  }),
}
</script>

<style scoped>
  .accounts {
    max-width: 100rem;
  }
</style>

