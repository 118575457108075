<template>
  <div class="proposal-summary">
    <v-card class="flex">
      <v-card-title>{{ name }}</v-card-title>
      <div class="mx-3 px-2 description" v-html="description"></div>
      <v-card-text class="d-flex flex-row layout-center">
        <ProposalCountdown
          v-if="pending"
          :to="votingOpensAt"
          :end="openVoting"
        >Voting opens in </ProposalCountdown>
        <ProposalCountdown
          v-if="active"
          :to="votingClosesAt"
          :end="closeVoting"
        >Voting closes in </ProposalCountdown>
        <div v-if="closed && !pending && !active">Voting Closed</div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ProposalCountdown from '@/components/ProposalCountdown'

export default {
  name: 'ProposalSummary',
  props: {
    name: String,
    description: String,
    votingOpensAt: Date,
    votingClosesAt: Date,
  },
  computed: {
    pending() {
      if ( this.now >= this.votingOpensAt ) return false
      return true
    },
    active() {
      if ( this.now < this.votingOpensAt ) return false
      if ( this.now >= this.votingClosesAt ) return false
      return true
    },
    closed() {
      if ( this.now < this.votingClosesAt ) return false
      return true
    },
  },
  components: {
    ProposalCountdown,
  },
  methods: {
    openVoting() {
      this.now = Date.now()
    },
    closeVoting() {
      this.now = Date.now()
    },
  },
  data: () => ({
    now: Date.now()
  }),
}
</script>

<style scoped>
  .description {
    border-left: 2px solid grey;
  }
</style>


