<template>
  <v-card class="counter d-flex flex-column align-center ma-3 px-3">
    <v-card-title class="title">
      <slot></slot>
    </v-card-title>
    <div class="text-h3 text-lg-h2">{{ value }}</div>
  </v-card>
</template>

<script>

export default {
  name: 'Counter',
  props: {
    value: String,
  },
}
</script>

<style scoped>
.counter {
  height: 10rem;
  min-width: 10rem;
}

.counter .title {
  text-align: center;
  word-break: normal;
  line-height: 1.5rem;
  min-height: 5rem;
}

@media (min-width: 1264px) {
  .counter {
    height: 10rem;
    min-width: 13rem;
    min-height: 0rem;
  }
}
</style>

