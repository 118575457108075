<template>
  <div>
    <v-card>
      <div class="d-flex">
        <v-card-title>
          <h2>{{ name }}</h2>
          <v-spacer></v-spacer>
        </v-card-title>
      </div>
      <v-card-text>Email: {{ email }}</v-card-text>
      <v-card-actions>
        <NavLink
          :route="passwordRoute"
          :params="{ id }"
          icon="key"
          color="orange"
          :responsive="false"
        >Edit Password</NavLink>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import NavLink from '@/components/NavLink'

export default {
  name: 'Profile',
  computed: {
    passwordRoute() {
      return this.id ?
        'AdminPasswordEdit' :
        'PasswordEdit'
    },
  },
  props: {
    id: Number,
    name: String,
    email: String,
  },
  components: {
    NavLink,
  },
}
</script>

