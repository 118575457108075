export function percentFilter( value ) {
  const percent = Math.round( value * 100 )
  if ( percent === 0 ) return '0%'
  if ( percent < 1 ) return '<1%'
  return `${percent}%`
}

export function balanceFilter( value ) {
  const precision = 100000000
  return (Math.round( value*precision ) / precision)
    .toLocaleString()
}

export function numberFilter( value ) {
  return Number(value).toLocaleString()
}
