<template>
  <div>
    <countdown :time="time" @end="end">
      <template slot-scope="props">
        <span><slot></slot></span>
        <span v-if="props.days > 0">
          <span> {{ props.days + 1 }} days</span>
        </span>
        <span v-if="props.hours > 0 && props.days < 1">
          <span> {{ props.hours + 1 }} hours</span>
        </span>
        <span v-if="props.minutes > 0 && props.hours < 1 && props.days < 1">
          <span> {{ props.minutes + 1 }} minutes</span>
        </span>
        <span v-if="props.minutes < 1 && props.hours < 1 && props.days < 1">
          <span>{{ props.seconds + 1 }}</span>
          <span v-if="props.minutes !== 1"> seconds</span>
          <span v-if="props.minutes === 1"> second</span>
        </span>
      </template>
    </countdown>
  </div>
</template>

<script>
export default {
  name: 'ProposalCountdown',
  props: {
    to: Date,
    end: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    time() {
      // filter out invalid dates
      if ( isNaN( this.to?.getTime() ) ) return null
      return Math.max( this.to.getTime() - Date.now(), 0 )
    },
  },
}
</script>

<style scoped>
  .name {
    min-width: 7rem;
  }
  .percent {
    min-width: 6rem;
    text-align: right
  }
</style>
