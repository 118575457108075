<template>
  <div class="proposals mx-auto">
    <Loading v-if="loading" type="table" />
    <div v-if="!loading">
      <ProposalList :proposals='proposals' />
      <router-link
        :to="{ name: 'ProposalCreate' }"
        v-slot="{ navigate }"
        v-if="isAdmin"
      >
        <v-fab-transition>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                fab
                fixed
                large
                bottom
                right
                @click="navigate"
                v-on="on"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Create New Proposal</span>
          </v-tooltip>
        </v-fab-transition>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading.vue'
import ProposalList from '@/components/ProposalList.vue'

export default {
  name: 'ProposalsView',
  components: {
    ProposalList,
    Loading,
  },
  computed: {
    ...mapGetters([ 'proposals', 'isAdmin' ])
  },
  watch: {
    proposals: function( proposals ) {
      this.loading = !proposals
    },
  },
  created() {
    this.fetchProposals()
  },
  methods: {
    ...mapActions([ 'fetchProposals' ]),
  },
  data: () => ({
    error: null,
    loading: true,
  }),
}
</script>

<style scoped>
  .proposals {
    max-width: 100rem;
  }
</style>
