<template>
  <div class="account-create mx-auto">
    <v-card>
      <v-card-title>Create Account</v-card-title>
      <v-container>
        <v-form v-model="valid" class="mx-auto form">
          <v-text-field
            v-model="name"
            placeholder="Name"
            :rules="nameRules"
            required
            ref="autofocus"
          >
          </v-text-field>
          <v-text-field
            v-model="email"
            placeholder="Email"
            :rules="emailRules"
            required
          >
          </v-text-field>
          <v-text-field
            v-model="password"
            placeholder="Password"
            :rules="passwordRules"
            type="password"
            required
          >
          </v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="submit" color="primary">
              <v-icon>mdi-plus</v-icon>
              <span class='mr-2'>Create</span>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AccountCreate',
  data: () => ({
    name: null,
    nameRules: [ v => !!v || 'Please specify a name.' ],
    email: null,
    emailRules: [ v => !!v || 'Please specify an email.' ],
    password: null,
    passwordRules: [],
    valid: false,
    response: {},
    showFormError: false,
    formError: null,
  }),
  mounted() {
    this.$refs.autofocus.focus()
  },
  methods: {
    ...mapActions([
      'createAccount',
      'showNotification',
      'showError',
      'resetNotification'
    ]),

    async submit() {
      this.resetNotification()
      if ( !this.valid ) {
        return this.showError( 'Please correct errors before submitting.' )
      }

      const { error, account } = await this.createAccount({
        name: this.name,
        email: this.email,
        password: this.password,
      })

      if ( error ) {
        return this.showError( error )
      }

      this.showNotification( 'Account Created' )
      this.$router.push({
        name: 'Account',
        params: { id: account.id },
      })
    }
  },
}
</script>

<style scoped>
  .account-create {
    max-width: 100rem;
  }

  .form {
    max-width: 40rem;
  }
</style>
