<template>
  <div>
    <v-card>
      <v-card-title>Addresses</v-card-title>

      <v-card-text v-if="!hasAddresses">No Registered Addresses</v-card-text>
      <v-simple-table class="addresses">
        <thead v-if="hasAddresses">
          <tr>
            <th>Verified</th>
            <th>Address</th>
            <th>Balance</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <AddressListItem
            v-for='address in addresses'
            :key='address.address'
            v-bind="address"
          />
          <tr class="font-weight-bold" v-if="hasAddresses">
            <td>Total</td>
            <td></td>
            <td>{{ totalBalance | balanceFilter }}</td>
            <td></td>
          </tr>
          
        </tbody>
      </v-simple-table>
      <v-divider />
      <v-form class="pa-4" v-model="valid">
        <div class="text-h4">Add a new address</div>
        <div class="d-flex">
          <v-text-field
            class="flex"
            v-model="newAddress"
            placeholder="Address"
            :rules="newAddressRules"
            @keypress.enter="submit"
            required
          />
          <v-btn
            @click="submit"
            color="primary"
            fab
            small
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash'
import AddressListItem from '@/components/AddressListItem'
import { balanceFilter } from '@/filters'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AddressList',
  data: () => ({
    valid: false,
    newAddress: null,
    newAddressRules: [ v => !!v || 'Please specify an address.' ],
  }),
  props: {
    addresses: Array,
    accountId: Number,
  },
  filters: {
    balanceFilter,
  },
  computed: {
    ...mapGetters([ 'isAdmin' ]),
    totalBalance() {
      return _( this.addresses )
        .filter( 'isActive' )
        .map( 'balance' )
        .map( parseFloat )
        .sum()
    },
    hasAddresses() {
      if ( !this.addresses ) return false
      if ( this.addresses.length === 0 ) return false
      return true
    },
  },
  methods: {
    ...mapActions([
      'createAddress',
      'showNotification',
      'showError',
      'resetNotification',
    ]),
    async submit( event ) {
      event.preventDefault()

      this.resetNotification()
      if ( !this.valid ) {
        return this.showError( 'Please correct errors before submitting.' )
      }

      const { error } = await this.createAddress({
        address: this.newAddress,
        accountId: this.accountId,
      })
      if ( error ) {
        return this.showError( error )
      }

      this.newAddress = null
      this.showNotification( 'Address Added' )
    },
  },
  components: {
    AddressListItem,
  },
}
</script>

<style scoped>
  .addresses {
    flex: 1 1 100%;
    white-space: nowrap;
  }
</style>
