<template>
  <div class="loading mx-auto d-flex flex-row align-center justify-center">
    <v-card>
      <v-card-text class="px-3">Loading...</v-card-text>
      <v-card-text>
        <v-progress-circular
          indeterminate
          size="50"
          color="orange"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Loading',
}
</script>

<style scoped>
  .loading {
    opacity: 0.5;
    max-width: 10rem;
  }
</style>
