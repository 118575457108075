import axios from 'axios'
import config from '../config/index.js'

export default {
  state: {
    address: {},
    signature: {},
    totalBalance: null,
  },

  getters: {
    totalBalance: (state) => state.totalBalance,
    address: (state) => state.address,
    signature: (state) => state.signature,
  },

  mutations: {
    addAddress( state, address ) {
      state.account.addresses.push( address )
    },
    setTotalBalance( state, totalBalance ) {
      state.totalBalance = totalBalance
    },
    setAccountAddress( state, address ) {
      const existingAddress = state.account.addresses
        .find( a => a.id === address.id )
      Object.assign( existingAddress, address ) 
    },
    setAddress( state, address ) {
      state.address = address
    },
    setSignature( state, signature ) {
      state.signature = signature
    },
  },

  actions: {
    async createAddress( {commit, dispatch}, {accountId, address} ) {
      const { data } = await axios.post(
        `${config.baseUrl}/address/`, {
          accountId,
          address,
        })
      if ( !data.error ) {
        commit( 'addAddress', data.address )
      }

      await dispatch( 'refreshVoteWeight', accountId )
      return data
    },

    async updateAddress( {commit, dispatch}, {addressId, addressData} ) {
      const { data } = await axios.put(
        `${config.baseUrl}/address/${addressId}`,
        addressData
      )
      if ( !data.error ) {
        commit( 'setAccountAddress', data.address )
      }
      await dispatch( 'refreshVoteWeight' )
      return data
    },

    async fetchTotalBalance( {commit} ) {
      const { data } = await axios.get( `${config.baseUrl}/totalBalance` )
      if ( data.error ) {
        console.error( data.error )
        return data
      }
      commit( 'setTotalBalance', data.totalBalance )
      return data
    },

    async fetchAddress( {commit}, addressId ) {
      const { data } = await axios.get( `${config.baseUrl}/address/${addressId}` )
      if ( data.error ) {
        console.error( data.error )
        return data
      }
      commit( 'setAddress', data.address )
      return data
    },

    async fetchSignature( {commit}, addressId ) {
      const { data } = await axios.post( `${config.baseUrl}/signature`, { addressId } )
      if ( data.error ) {
        console.error( data.error )
        return data
      }
      commit( 'setSignature', data.signature )
      return data
    },

    async signSignature( {commit}, { signatureId, signature } ) {
      const { data } = await axios.put(
        `${config.baseUrl}/signature/${signatureId}`,
        { signature },
      )
      if ( data.error ) {
        console.error( data.error )
        return data
      }
      commit( 'setSignature', {} )
      return data
    },
  },
}
