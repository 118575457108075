<template>
  <div class="home d-flex flex-column align-center justify-center">
    <v-card class="d-flex flex-column align-center mx-auto pa-3">
      <v-card-title class="bvc-title text-h5 text-lg-h3 text-center">Blockchain Ventures Association</v-card-title>
      <v-card-subtitle class="text-h6 text-center">Empowering the community. Growing the Ecosystem.</v-card-subtitle>
      <v-card-subtitle class="subtitle-1 ma-2 text-center">The Blockchain Ventures Association is a collection of like-minded individuals and companies focused on furthering the mission of delivering <strong><i>peer-to-peer cash</i></strong> to the world.</v-card-subtitle>
    </v-card>

    <Counter
      :value="totalBalanceFormatted"
      v-if="totalBalance > 0"
    >Association Assets</Counter>

    <div class="counter d-sm-flex pa-2 justify-center align-center">
      <Counter
        :value="formatNumber( numMembers )"
      >Members</Counter>
      <Counter
        :value="formatNumber( numSeats )"
        v-if="numSeats > 0"
      >GNC Seats</Counter>
      <Counter
        value="TBD"
        :v-if="numSeats === 0"
      >GNC Seats</Counter>
      <Counter
        :value="formatNumber( numActiveProposals )"
        v-if="numMembers > 0"
      >Active Proposals</Counter>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Counter from '@/components/Counter'

export default {

  name: 'HomeView',

  components: {
    Counter,
  },

  data: () => ({
    majorProjects: []
  }),

  computed: {
    ...mapGetters([
      'numMembers',
      'numSeats',
      'numActiveProposals',
      'totalBalance',
      'isLoggedIn',
    ]),
    totalBalanceFormatted() {
      const PRECISION = 1
      const SATS_PER_UNIT = 100
      const rounded = Math.round( this.totalBalance / SATS_PER_UNIT * PRECISION )
      return Number(rounded / PRECISION)
        .toLocaleString()
    }
  },

  methods: {
    ...mapActions([
      'fetchNumMembers',
      'fetchNumSeats',
      'fetchNumActiveProposals',
      'fetchTotalBalance'
    ]),
    formatNumber: (val) => Number(val).toLocaleString(),
  },

  async created() {
    await this.fetchNumActiveProposals()
    await this.fetchNumSeats()
    await this.fetchNumMembers()

    if ( this.isLoggedIn ) {
      await this.fetchTotalBalance()
    }
  },
}
</script>

<style scoped>
.v-card__title.bvc-title {
  word-break: break-word;

}
@media (min-width: 1264px) {
  .text-h6 {
    font-style: italic;
  }
  .home * {
    font-family: Lato !important;
  }
  .projects {
    width: 100%
  }
}
</style>

