<template>
  <v-list class="py-0">
    <v-list-item v-if="proposals.length === 0">No Active Proposals</v-list-item>
    <div v-for='(proposal, index) in proposals' :key='proposal.id'>
      <ProposalListItem v-bind='proposal' />
      <v-divider v-if='index < proposals.length - 1'></v-divider>
    </div>
  </v-list>
</template>

<script>
import ProposalListItem from '@/components/ProposalListItem.vue'

export default {
  name: 'ProposalList',
  components: {
    ProposalListItem,
  },
  props: {
    proposals: Array,
  },
}
</script>

<style scoped>
</style>
