import axios from 'axios'
import config from '../config/index.js'

axios.defaults.headers.common.Authorization = localStorage.getItem('token')

export default {

  state: {
    yourName: localStorage.getItem( 'yourName' ),
    account: {},
    accounts: [],
    voteWeight: 0,
    numMembers: 0,
  },

  getters: {
    accounts: (state) => state.accounts,
    account: (state) => state.account,
    isAdmin: (state) => state.isAdmin,
    accountId: (state) => state.account?.id,
    voteWeight: (state) => state.voteWeight,
    numMembers: (state) => state.numMembers,
  },

  mutations: {
    unsetAccounts( state ) {
      state.accounts = []
    },
    unsetAccount( state ) {
      state.account = {}
      state.voteWeight = 0
    },
    setAccounts( state, accounts ) {
      state.accounts = accounts
    },
    setAccount( state, account ) {
      state.account = account
      state.voteWeight = account.voteWeight
    },
    setVoteWeight( state, voteWeight ) {
      state.voteWeight = voteWeight
    },
    setNumMembers( state, numMembers ) {
      state.numMembers = numMembers
    },
  },

  actions: {
    async fetchAccounts( {commit} ) {
      commit( 'unsetAccounts' )
      const { data } = await axios.get( `${config.baseUrl}/accounts` )
      if ( data.error ) {
        console.error( data.error )
        return data
      }

      commit( 'setAccounts', data )
    },

    async fetchAccount( {commit}, id ) {
      commit( 'unsetAccount' )

      const url = id ?
        `${config.baseUrl}/account/${id}` :
        `${config.baseUrl}/profile`

      const { data } = await axios.get( url )
      if ( data.error ) {
        console.error( data.error )
        return data
      }
      commit( 'setAccount', data )

      return data
    },

    async createAccount( {commit}, account ) {
      const { data } = await axios.post( `${config.baseUrl}/account/`, account )
      if ( data.error ) {
        console.error( data.error )
        return data
      }

      commit( 'setAccount', data )
      return data
    },

    async refreshVoteWeight( {commit}, id ) {
      const url = id ?
        `${config.baseUrl}/account/${id}` :
        `${config.baseUrl}/profile`
      const { data } = await axios.get( url )
      if ( data.error ) {
        console.error( data.error )
        return data
      }

      commit( 'setVoteWeight', data.voteWeight )
      return data
    },

    async fetchNumMembers( {commit} ) {
      const { data } = await axios.get( `${config.baseUrl}/numMembers` )
      if ( data.error ) {
        console.error( data.error )
        return data
      }
      commit( 'setNumMembers', data.numMembers )
    },
  },
}
