<template>
  <v-list-item>
    <div class="d-flex flex-column align-center py-2">
      <div class="d-none d-sm-flex flex-column align-center">
        <div class="text-subtitle-2 text-md-h5">Acceptance</div>
        <Acceptance
          :accepted="acceptedPercent"
          :rejected="rejectedPercent"
          :abstained="abstainedPercent"
        />
      </div>
    </div>
    <v-divider vertical class="mx-3 d-none d-sm-block"></v-divider>
    <div class="description">
      <v-list-item-title class="text-title text-sm-h6 text-md-h5">{{ name }}</v-list-item-title>
      <v-list-item-subtitle class="text-caption text-sm-subtitle-1">
        <ProposalCountdown
          v-if="pending"
          :end="openVoting"
          :to="votingOpensAt"
        >Voting opens in </ProposalCountdown>
        <ProposalCountdown
          v-if="active"
          :end="closeVoting"
          :to="votingClosesAt"
        >Voting is open for </ProposalCountdown>
        <div v-if="closed">Voting Closed</div>
      </v-list-item-subtitle>
    </div>
    <v-list-item-action class="mx-3 d-flex flex-column align-center justify-center">
      <v-list-item-action-text class="d-none d-sm-block">
        <div class="text-caption text-md-subtitle-1" v-if="!isLoggedIn || pending">View</div>
        <div class="text-caption text-md-subtitle-1" v-if="isLoggedIn && active && !hasVoted">Awaiting Vote</div>
        <div class="text-caption text-md-subtitle-1" v-if="isLoggedIn && active && hasVoted">Vote Received</div>
      </v-list-item-action-text>
      <router-link :to="{ name: 'Proposal', params: { id } }" v-slot="{ navigate }">
        <div class="py-2 d-none d-sm-block">
          <v-btn
            fab
            color="orange"
            large
            v-if="isLoggedIn && !hasVoted && active"
            @click="navigate"
          >
            <v-icon>mdi-vote</v-icon>
          </v-btn>
          <v-btn
            fab
            color="primary"
            large
            v-if="!isLoggedIn || hasVoted || !active"
            @click="navigate"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </div>

        <!--
          NOTE: This is copied from above until Scott can learn to do
          responsive vue html attributes
        -->
        <div class="py-2 d-block d-sm-none">
          <v-btn
            fab
            color="orange"
            small
            v-if="isLoggedIn && !hasVoted && active"
            @click="navigate"
          >
            <v-icon>mdi-vote</v-icon>
          </v-btn>
          <v-btn
            fab
            color="primary"
            small
            v-if="!isLoggedIn || hasVoted || !active"
            @click="navigate"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </div>
      </router-link>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProposalCountdown from '@/components/ProposalCountdown'
import Acceptance from '@/components/Acceptance'

export default {
  name: 'ProposalListItem',
  data: () => ({
    now: Date.now(),
  }),
  computed: {
    ...mapGetters([ 'isLoggedIn' ]),
    timeBeforeVotingOpen() {
      return this.votingOpensAt.getTime() - this.now
    },
    timeBeforeVotingClosed() {
      return this.votingClosesAt - this.now
    },
    pending() {
      if ( this.timeBeforeVotingOpen <= 0 ) return false
      return true
    },
    active() {
      if ( this.timeBeforeVotingOpen > 0 ) return false
      if ( this.timeBeforeVotingClosed <= 0 ) return false
      return true
    },
    closed() {
      if ( this.timeBeforeVotingClosed > 0 ) return false
      return true
    },
    acceptedPercent() { return this.accepted * 100 },
    rejectedPercent() { return this.rejected * 100 },
    abstainedPercent() { return this.abstained * 100 },
  },
  beforeDestroy() {
    clearInterval( this.progressUpdate )
  },
  props: {
    id: Number,
    name: String,
    description: String,
    votes: Number,
    votingClosesAt: Date,
    votingOpensAt: Date,
    hasVoted: Boolean,
    accepted: Number,
    rejected: Number,
    abstained: Number,

    votingOpenTimeout: null,
    votingClosesTimeout: null,
  },
  methods: {
    ...mapActions([ 'vote', 'fetchProposal' ]),
    openVoting() {
      this.now = Date.now()
    },
    closeVoting() {
      this.now = Date.now()
    },
  },
  components: {
    ProposalCountdown,
    Acceptance,
  },
}
</script>

<style scoped>
  .description {
    flex: 1 1 100%;
  }

  .description div {
    white-space: normal;
  }
</style>
