<template>
  <div class="percent-progress">
    <v-card class="vote-weight d-flex flex-column align-center">
      <v-card-title>
        <slot></slot>
      </v-card-title>
      <v-card-text>
        <v-progress-circular
          class="progress"
          size="200"
          width="15"
          color="teal"
          :value="valuePercent"
        >{{ value | percentFilter }}</v-progress-circular>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { percentFilter } from '@/filters'

export default {
  name: 'PercentProgress',
  computed: {
    valuePercent() {
      return this.value * 100
    },
  },
  filters: {
    percentFilter,
  },
  props: {
    value: Number,
  },
}
</script>

<style scoped>
.progress {
  font-size: 2rem;
}
</style>

