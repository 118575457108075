import Vue from 'vue'
import Vuex from 'vuex'

import AccountCtrl from '@/store/AccountCtrl'
import AddressCtrl from '@/store/AddressCtrl'
import AssCtrl from '@/store/AssCtrl'
import AuthCtrl from '@/store/AuthCtrl'
import NotificationCtrl from '@/store/NotificationCtrl'
import ProposalCtrl from '@/store/ProposalCtrl'

const controllers = [
  AccountCtrl,
  AddressCtrl,
  AssCtrl,
  AuthCtrl,
  NotificationCtrl,
  ProposalCtrl,
]

const vuexConfig = {
  state: {},
  getters: {},
  mutations: {},
  actions: {},
}

for ( const controller of controllers ) {
  Object.assign( vuexConfig.state, controller.state )
  Object.assign( vuexConfig.getters, controller.getters )
  Object.assign( vuexConfig.mutations, controller.mutations )
  Object.assign( vuexConfig.actions, controller.actions )
}


Vue.use(Vuex)
export default new Vuex.Store( vuexConfig )
