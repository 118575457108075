import axios from 'axios'
import config from '../config/index.js'

axios.defaults.headers.common.Authorization = localStorage.getItem('token')

export default {

  state: {
    proposal: null,
    proposals: null,
    numActiveProposals: 0,
  },

  getters: {
    proposals: (state) => state.proposals,
    proposal: (state) => state.proposal,
    activeProposals: ({ proposals }) => {
      if ( !proposals ) return []
      return proposals.filter( p => p.isActive )
    },
    numActiveProposals: (state) => state.numActiveProposals,
  },

  mutations: {
    unsetProposals( state ) {
      state.proposals = null
    },
    unsetProposal( state ) {
      state.proposal = null
    },
    setProposals( state, proposals ) {
      state.proposals = proposals
    },
    setProposal( state, proposal ) {
      if ( proposal ) {
        proposal.votingOpensAt = new Date( proposal.votingOpensAt )
        proposal.votingClosesAt = new Date( proposal.votingClosesAt )
      }

      state.proposal = proposal
    },
    setNumActiveProposals( state, numActiveProposals ) {
      state.numActiveProposals = numActiveProposals
    },
  },

  actions: {
    async fetchProposals( {commit} ) {
      commit( 'unsetProposals' )
      const { data } = await axios.get( `${config.baseUrl}/proposal` )
      for ( const proposal of data ) {
        proposal.votingOpensAt = new Date( proposal.votingOpensAt )
        proposal.votingClosesAt = new Date( proposal.votingClosesAt )
      }
      commit( 'setProposals', data )
    },
    async fetchProposal( {commit}, id ) {
      commit( 'unsetProposal', id )
      const { data } = await axios.get( `${config.baseUrl}/proposal/${id}` )
      commit( 'setProposal', data )
      return data
    },

    async createProposal( {commit}, proposal ) {
      const { data } = await axios.post( `${config.baseUrl}/proposal/`, proposal )
      if ( data.error ) {
        console.error( data.error )
        return data
      }

      commit( 'setProposal', data )
      return data
    },

    async fetchNumActiveProposals({ commit }) {
      const { data } = await axios.get( `${config.baseUrl}/proposal/numActive` )
      if ( data.error ) {
        console.error( data.error )
        return data
      }

      commit( 'setNumActiveProposals', data.numActiveProposals )
      return data
    },

    async accept( {commit}, proposalId ) {
      const { data: voteResponse } = await axios.post( `${config.baseUrl}/accept/${proposalId}` )
      if ( voteResponse.error ) return voteResponse
      const { data: proposal } = await axios.get( `${config.baseUrl}/proposal/${proposalId}` )
      commit( 'setProposal', proposal )
      return voteResponse
    },

    async reject( {commit}, proposalId ) {
      const { data: voteResponse } = await axios.post( `${config.baseUrl}/reject/${proposalId}` )
      if ( voteResponse.error ) return voteResponse
      const { data: proposal } = await axios.get( `${config.baseUrl}/proposal/${proposalId}` )
      commit( 'setProposal', proposal )
      return voteResponse
    },

    async abstain( {commit}, proposalId ) {
      const { data: voteResponse } = await axios.post( `${config.baseUrl}/abstain/${proposalId}` )
      if ( voteResponse.error ) return voteResponse
      const { data: proposal } = await axios.get( `${config.baseUrl}/proposal/${proposalId}` )
      commit( 'setProposal', proposal )
      return voteResponse
    },
  },
}
