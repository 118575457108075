<template>
  <div class="d-flex align-center pa-3">
    <div class="px-sm-2 px-md-5 name text-caption text-sm-body-1">{{ name }}</div>
    <v-progress-linear
      :color="color"
      :value="value"
      :height="height"
    ></v-progress-linear>
    <div class="px-sm-2 px-md-5 percent text-caption text-sm-body-1">{{ value }}%</div>
  </div>
</template>
<script>
export default {
  name: 'ProgressBar',
  props: {
    name: String,
    value: Number,
    color: String,
    height: {
      type: Number,
      default: 10,
    }
  },
}
</script>

<style scoped>
.name {
  min-width: 4rem;
}
.percent {
  min-width: 3rem;
  text-align: right
}
.v-progress-linear {
  border-radius: 3px;
}

@media (min-width: 600px) {
  .name {
    min-width: 6rem;
  }
  .percent {
    min-width: 4rem;
  }
}

@media (min-width: 960px) {
  .name {
    min-width: 9rem;
  }
  .percent {
    min-width: 6.5rem;
  }
}
</style>

