import Vue from 'vue'
import VueRouter from 'vue-router'

import AccountCreate from '../views/AccountCreate.vue'
import AccountView from '../views/AccountView.vue'
import AccountsView from '../views/AccountsView.vue'
import AddressView from '../views/AddressView.vue'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import PasswordEditView from '../views/PasswordEditView.vue'
import ProposalCreate from '../views/ProposalCreate.vue'
import ProposalView from '../views/ProposalView.vue'
import ProposalsView from '../views/ProposalsView.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },
  {
    path: '/proposal',
    name: 'Proposals',
    component: ProposalsView,
  },
  {
    path: '/proposal/create',
    name: 'ProposalCreate',
    component: ProposalCreate,
    meta: { requiresAuth: true },
  },
  {
    path: '/proposal/:id',
    name: 'Proposal',
    component: ProposalView,
    props: (route) => {
      const id = Number.parseInt( route.params.id, 10 )
      if ( Number.isNaN( id ) ) {
        return 0
      }
      return { id }
    },
  },
  {
    path: '/address/:id',
    name: 'Address',
    component: AddressView,
    props: (route) => {
      const id = Number.parseInt( route.params.id, 10 )
      if ( Number.isNaN( id ) ) {
        return 0
      }
      return { id }
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/account/password',
    name: 'PasswordEdit',
    component: PasswordEditView,
    meta: { requiresAuth: true },
  },
  {
    path: '/account/password/:id',
    name: 'AdminPasswordEdit',
    component: PasswordEditView,
    props: (route) => {
      const id = Number.parseInt( route.params.id, 10 )
      if ( Number.isNaN( id ) ) {
        return 0
      }
      return { id }
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: AccountsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/account/create',
    name: 'AccountCreate',
    component: AccountCreate,
    meta: { requiresAuth: true },
  },
  {
    path: '/account',
    name: 'Profile',
    component: AccountView,
    meta: { requiresAuth: true },
  },
  {
    path: '/account/:id',
    name: 'Account',
    component: AccountView,
    props: (route) => {
      const id = Number.parseInt( route.params.id, 10 )
      if ( Number.isNaN( id ) ) {
        return 0
      }
      return { id }
    },
    meta: { requiresAuth: true },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach( async (to, from, next) => {
  if ( !to.matched.some( record => record.meta.requiresAuth ) )
    return next()

  if ( store.getters.isLoggedIn )
    return next()

  if ( store.getters.token ) {
    const { error } = await store.dispatch( 'refreshToken' )

    if ( !error ) {
      return next()
    }
  }

  return next({ name: 'Login' })
})

export default router
