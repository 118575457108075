<template>
  <router-link :to="{ name: route, params }" v-slot="{ navigate, isExactActive }">
    <v-btn
      class="nav-link mx-2"
      @click="navigate"
      :color="color"
      :disabled="isExactActive"
    >
      <v-icon>mdi-{{ icon }}</v-icon>
      <span :class="labelStyle">
        <slot></slot>
      </span>
    </v-btn>
  </router-link>
</template>

<script>
export default {
  name: 'NavLink',
  computed: {
    labelStyle() {
      return {
        'mr-2': true,
        'd-none': this.responsive,
        'd-sm-block': this.responsive,
      }
    },
  },
  props: {
    route: String,
    icon: String,
    params: {
      type: Object,
      default: () => ({}),
    },
    responsive: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: 'default',
    },
  },
}
</script>

<style scoped>
</style>
